import BasePlugin from '../BasePlugin'

export default class CalculatingAdditionIndicatorsInInvestmentExpensesBudget extends BasePlugin {
  async execute () {
    let budgetId = this.context.getModel().id
    let data = { 'budgetId': budgetId }
    let cont = this.context
    cont.getCard().setLoading(true)
    cont.$http.post(`${cont.$config.api}/registryservice/plugins/execute/CalculatingAdditionIndicatorsInInvestmentExpensesBudget`,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(function () {
      cont.getDashboardComponents()['component_dd5282b5-5ef2-4835-b5a0-1e3eb06db093'][0].loadData()
      cont.$http.post(`${cont.$config.api}/registryservice/plugins/execute/InvestmentExpensesBudget/CalculateDeprecationCommand`,
        { 'investmentExpensesBudgetId': cont.getModel().id },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then((response2) => {
        console.log('Амортизация расчитана')
        cont.getDashboardComponents()['component_dd5282b5-5ef2-4835-b5a0-1e3eb06db093'][0].loadData()
        cont.getCard().setLoading(false)
      })
    }).catch(function () {
      cont.getCard().setLoading(false)
    })
  }
}
